import PropTypes from 'prop-types';
import CategoryDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/CategoryDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';

const CategoryModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    const contentWrapperStyles = getContentWrapperStyles();

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)', ...contentWrapperStyles })}>
            <CategoryDataTransformerBlock {...rest} />
        </section>
    );
};

CategoryModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default CategoryModule;
